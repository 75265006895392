<template>
	<div>
		<div
			class="modal fade"
			id="text-editor-modal"
			data-keyboard="false"
			data-backdrop="false"
			tabindex="-1"
			role="dialog"
			aria-labelledby="text-editor-Title"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
					</div>
					<div class="modal-body">
						<trix-toolbar id="trix-toolbar">
							<div class="trix-button-row">
								<span
									class="trix-button-group trix-button-group--text-tools"
									data-trix-button-group="text-tools"
								>
									<button
										type="button"
										class="trix-button trix-button--icon trix-button--icon-bold"
										data-trix-attribute="bold"
										data-trix-key="b"
										title="Bold"
										tabindex="-1"
									>
										Bold
									</button>
									<button
										type="button"
										class="
											trix-button trix-button--icon trix-button--icon-italic
										"
										data-trix-attribute="italic"
										data-trix-key="i"
										title="Italic"
										tabindex="-1"
									>
										Italic
									</button>
									<button
										type="button"
										class="
											trix-button trix-button--icon trix-button--icon-strike
										"
										data-trix-attribute="strike"
										title="Strikethrough"
										tabindex="-1"
									>
										Strikethrough
									</button>
									<button
										type="button"
										class="trix-button trix-button--icon trix-button--icon-link"
										data-trix-attribute="href"
										data-trix-action="link"
										data-trix-key="k"
										title="Link"
										tabindex="-1"
									>
										Link
									</button>
								</span>

								<span
									class="trix-button-group trix-button-group--block-tools"
									data-trix-button-group="block-tools"
								>
									<button
										type="button"
										class="
											trix-button trix-button--icon trix-button--icon-heading-1
										"
										data-trix-attribute="heading5"
										title="Heading"
										tabindex="-1"
										data-trix-active=""
									>
										Heading
									</button>
									<button
										type="button"
										class="trix-button trix-button--icon trix-button--icon-code"
										data-trix-attribute="code"
										title="Code"
										tabindex="-1"
										data-trix-active=""
									>
										Code
									</button>
									<button
										type="button"
										class="
											trix-button trix-button--icon trix-button--icon-indent
										"
										data-trix-attribute="indent"
										title="Indent"
										tabindex="-1"
									>
										Indent
									</button>
									<button
										type="button"
										class="
											trix-button
											trix-button--icon
											trix-button--icon-bullet-list
										"
										data-trix-attribute="bullet"
										title="Bullets"
										tabindex="-1"
									>
										Bullets
									</button>
									<button
										type="button"
										class="
											trix-button
											trix-button--icon
											trix-button--icon-number-list
										"
										data-trix-attribute="number"
										title="Numbers"
										tabindex="-1"
									>
										Numbers
									</button>
									<button
										type="button"
										class="
											trix-button
											trix-button--icon
											trix-button--icon-decrease-nesting-level
										"
										data-trix-action="decreaseNestingLevel"
										title="Decrease Level"
										tabindex="-1"
										disabled=""
									>
										Decrease Level
									</button>
									<button
										type="button"
										class="
											trix-button
											trix-button--icon
											trix-button--icon-increase-nesting-level
										"
										data-trix-action="increaseNestingLevel"
										title="Increase Level"
										tabindex="-1"
										disabled=""
									>
										Increase Level
									</button>
								</span>

								<span
									class="trix-button-group trix-button-group--table-tools"
									data-trix-button-group="table-tools"
								>
									<button
										type="button"
										class="
											trix-button trix-button--icon trix-button--icon-table
										"
										data-trix-action="x-table"
										title="Add Table"
										tabindex="-1"
									>
										Add Table
									</button>
								</span>

								<span
									class="trix-button-group trix-button-group--history-tools"
									data-trix-button-group="history-tools"
								>
									<button
										type="button"
										class="trix-button trix-button--icon trix-button--icon-undo"
										data-trix-action="undo"
										data-trix-key="z"
										title="Undo"
										tabindex="-1"
										disabled=""
									>
										Undo
									</button>
									<button
										type="button"
										class="trix-button trix-button--icon trix-button--icon-redo"
										data-trix-action="redo"
										data-trix-key="shift+z"
										title="Redo"
										tabindex="-1"
										disabled=""
									>
										Redo
									</button>
								</span>
							</div>

							<div class="trix-dialogs" data-trix-dialogs="">
								<div
									class="trix-dialog trix-dialog--link"
									data-trix-dialog="href"
									data-trix-dialog-attribute="href"
								>
									<div class="trix-dialog__link-fields">
										<input
											type="url"
											name="href"
											class="trix-input trix-input--dialog"
											placeholder="Enter a URL…"
											aria-label="URL"
											required=""
											data-trix-input=""
											disabled="disabled"
										/>
										<div class="trix-button-group">
											<input
												type="button"
												class="trix-button trix-button--dialog"
												value="Link"
												data-trix-method="setAttribute"
											/>
											<input
												type="button"
												class="trix-button trix-button--dialog"
												value="Unlink"
												data-trix-method="removeAttribute"
											/>
										</div>
									</div>
								</div>
							</div>
						</trix-toolbar>
						<trix-editor id="text-editor" toolbar="trix-toolbar"></trix-editor>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary text-editor-cancel-btn"
						>
							Close
						</button>
						<button
							type="button"
							class="btn btn-primary text-editor-save-btn"
							data-save-in-process-text="Saving..."
						>
							Save changes
						</button>
					</div>
					<div class="confirm-delete-dialog d-none">
						<div>
							<p>Delete description?</p>
						</div>
						<div>
							<button
								type="button"
								class="btn btn-secondary confirm-delete-dialog__cancel-btn"
							>
								Cancel
							</button>
							<button
								type="button"
								class="btn btn-danger confirm-delete-dialog__confirm-btn"
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade"
			id="short-text-editor-modal"
			data-keyboard="false"
			data-backdrop="false"
			tabindex="-1"
			role="dialog"
			aria-labelledby="short-text-editor-Title"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
					</div>
					<div class="modal-body">
						<input
							id="short-text-editor"
							type="text"
							autocomplete="off"
							maxlength="255"
						/>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary text-editor-cancel-btn"
						>
							Close
						</button>
						<button
							type="button"
							class="btn btn-primary text-editor-save-btn"
							data-save-in-process-text="Saving..."
						>
							Save changes
						</button>
					</div>
					<div class="confirm-delete-dialog d-none">
						<div>
							<p>Delete description?</p>
						</div>
						<div>
							<button
								type="button"
								class="btn btn-secondary confirm-delete-dialog__cancel-btn"
							>
								Cancel
							</button>
							<button
								type="button"
								class="btn btn-danger confirm-delete-dialog__confirm-btn"
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade"
			id="plain-text-editor-modal"
			data-keyboard="false"
			data-backdrop="false"
			tabindex="-1"
			role="dialog"
			aria-labelledby="plain-text-editor-Title"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
					</div>
					<div class="modal-body">
						<textarea id="plain-text-editor"> </textarea>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary text-editor-cancel-btn"
						>
							Close
						</button>
						<button
							type="button"
							class="btn btn-primary text-editor-save-btn"
							data-save-in-process-text="Saving..."
						>
							Save changes
						</button>
					</div>
					<div class="confirm-delete-dialog d-none">
						<div>
							<p>Delete description?</p>
						</div>
						<div>
							<button
								type="button"
								class="btn btn-secondary confirm-delete-dialog__cancel-btn"
							>
								Cancel
							</button>
							<button
								type="button"
								class="btn btn-danger confirm-delete-dialog__confirm-btn"
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade"
			id="table-editor-modal"
			data-keyboard="false"
			data-backdrop="false"
			tabindex="-1"
			role="dialog"
			aria-labelledby="text-editor-Title"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<table-editor></table-editor>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TableEditor from "./TableEditor.vue";
import Trix from "trix";

const $ = window.$;

export async function showModal(nextModal) {
	let promise = new Promise((resolve) => {
		$(nextModal).one("shown.bs.modal", () => {
			window.restdocCurrentModal = nextModal;
			resolve();
		});
	});
	if (window.restdocCurrentModal) {
		$(window.restdocCurrentModal).one("hidden.bs.modal", () => {
			$(nextModal).modal("show");
		});
		$(window.restdocCurrentModal).modal("hide");
	} else {
		$(nextModal).modal("show");
	}

	return promise;
}

export default {
	components: {
		TableEditor
	},
	data: function () {
		return {
			componentName: " description editor ",
			currentModal: null,
			inProgress: false,
			resolver: null,
			rejecter: null,
			descriptionEditorOptions: null
		};
	},
	computed: {
		descriptionEditorTrixEditor: function () {
			return document.getElementById("text-editor");
		},
		tableEditor: function () {
			return document.getElementById("table-editor-element");
		},
		descriptionEditorModal: function () {
			return document.getElementById("text-editor-modal");
		},
		shortDescriptionEditorModal: function () {
			return document.getElementById("short-text-editor-modal");
		},
		plainTextEditorModal: function () {
			return document.getElementById("plain-text-editor-modal");
		},
		shortDescriptionEditorInput: function () {
			return document.getElementById("short-text-editor");
		},
		descriptionEditorTableButton: function() {
			return this.descriptionEditorModal.querySelector("button[data-trix-action='x-table']");
		}
	},
	methods: {
		editField: async function (editorOptions) {
			if (this.inProgress) {
				return new Promise((resolve, reject) => {
					reject("editor already in progress");
				});
			}
			this.inProgress = true;
			let promise = new Promise((resolve, reject) => {
				this.resolver = resolve;
				this.rejecter = reject;
			});

			await this.showDescriptionEditor(editorOptions);

			return promise;
		},
		getAttachmentAtSelectedRange: function () {
			let selectedRange =
				this.descriptionEditorTrixEditor.editor.composition.getSelectedRange();
			if (selectedRange !== null) {
				return this.descriptionEditorTrixEditor.editor.composition.getAttachmentAtRange(
					selectedRange
				);
			} else {
				return null;
			}
		},
		insertTable: async function () {
			let srcTableHtml = null;
			let selectedTableAttachment = this.getAttachmentAtSelectedRange();
			if (
				selectedTableAttachment &&
				selectedTableAttachment instanceof Trix.Attachment &&
				selectedTableAttachment.getContentType() ===
					"application/table-editor-table"
			) {
				srcTableHtml = selectedTableAttachment.getContent();
			}
			try {
				let outputTableHtml = await window.restdocApiEditTable(
					srcTableHtml
				);

				//Create and insert attachment
				let attachment = new Trix.Attachment({
					content: outputTableHtml,
					contentType: "application/table-editor-table"
				});
				this.descriptionEditorTrixEditor.editor.insertAttachment(attachment);
			} catch (e) {
				if (e instanceof Error) {
					//error happened
					throw e;
				} else {
					//promise rejected
					console.log(e);
				}
			} finally {
				await showModal(this.descriptionEditorModal);
				this.descriptionEditorTrixEditor.focus();
			}
		},
		showDescriptionEditor: async function (editorOptions) {
			let editor;
			let modal;
			if (editorOptions.shortDescr) {
				modal = this.shortDescriptionEditorModal;
				editor = this.shortDescriptionEditorInput;
			} else if (editorOptions.plainText) {
				modal = this.plainTextEditorModal;
				editor = this.plainTextEditor;
			} else {
				modal = this.descriptionEditorModal;
				editor = this.descriptionEditorTrixEditor;
			}

			modal.getElementsByClassName("modal-title")[0].innerText =
				editorOptions.editorTitle;

			if (editorOptions.fieldValue) {
				editor.value = editorOptions.fieldValue;
			} else {
				editor.value = "";
			}

			this.descriptionEditorOptions = editorOptions;

			await showModal(modal);

			editor.focus();

			//If trixEditor, move selection to the end
			if (!editorOptions.shortDescr && !editorOptions.plainText) {
				editor.editor.setSelectedRange(
					editor.editor.getDocument().toString().length - 1
				);
			}
		},
		exitWithoutSaving: async function () {
			await this.hideModal();
			this.inProgress = false;
			this.rejecter("edits not saved");
		},

		saveDescription: async function (btn) {
			let keepEditor = false;
			let editor;
			let modal;
			let editorOptions = this.descriptionEditorOptions;
			if (editorOptions.shortDescr) {
				modal = this.shortDescriptionEditorModal;
				editor = this.shortDescriptionEditorInput;
			} else if (editorOptions.plainText) {
				modal = this.plainTextEditorModal;
				editor = this.plainTextEditor;
			} else {
				modal = this.descriptionEditorModal;
				editor = this.descriptionEditorTrixEditor;
			}

			let btnDefaultText = btn.innerText;
			btn.innerText = btn.dataset.saveInProcessText;
			btn.disabled = true;
			btn.parentElement.getElementsByClassName(
				"text-editor-cancel-btn"
			)[0].disabled = true;

			let output;
			if (editorOptions.skipRemoteSave) {
				output = editor.value;
			} else {
				let updateData = {};
				if (editorOptions.updateData) {
					updateData = editorOptions.updateData;
				} else {
					updateData = {
						GroupId: editorOptions?.groupId || 0,
						ServiceId: editorOptions?.serviceId || 0,
						MethodId: editorOptions.methodId,
						ResponseCode: editorOptions.responseCode,
						ParamName: editorOptions.paramName,
						FieldType: editorOptions.fieldType,
						ShortDescr: editorOptions.shortDescr,
						Lang: this.$route.params["lang"] ?? "lv"
					};
				}
				let empty;
				if (editorOptions.shortDescr || editorOptions.plainText) {
					empty = !editor.value.trim();
				} else {
					empty = !editor.editor.getDocument().toString().trim();
				}
				let valueName;
				if (editorOptions.valueName) {
					valueName = editorOptions.valueName;
				} else {
					valueName = "FieldValue";
				}
				if (empty) {
					if (await this.confirmDelete(modal)) {
						updateData[valueName] = "";
					} else {
						btn.innerText = btnDefaultText;
						btn.disabled = false;
						btn.parentElement.getElementsByClassName(
							"text-editor-cancel-btn"
						)[0].disabled = false;
						return;
					}
				} else {
					updateData[valueName] = editor.value;
				}

				let fetchUrl;
				if (editorOptions.fetchUrl) {
					fetchUrl = editorOptions.fetchUrl;
				} else {
					fetchUrl = "/_/API/DescriptionManagement/UpdateDescription";
				}

				let response = await fetch(fetchUrl, {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify(updateData)
				});
				if (!response.ok) {
					console.log(`Server responded with code ${response.status}`);
					let errorMsg = await response.text();
					console.log(errorMsg);
					alert(errorMsg);

					keepEditor = true;
				} else {
					if (empty) {
						output = null;
					} else {
						output = await response.text();
					}
				}
			}
			if (!keepEditor) {
				await this.hideModal();
				this.resolver(output);
			}

			btn.innerText = btnDefaultText;
			btn.disabled = false;
			btn.parentElement.getElementsByClassName(
				"text-editor-cancel-btn"
			)[0].disabled = false;
			this.inProgress = false;
		},

		confirmDelete: async function (modal) {
			let confirmDialog = modal.getElementsByClassName(
				"confirm-delete-dialog"
			)[0];
			let cancelBtn = confirmDialog.getElementsByClassName(
				"confirm-delete-dialog__cancel-btn"
			)[0];
			let confirmBtn = confirmDialog.getElementsByClassName(
				"confirm-delete-dialog__confirm-btn"
			)[0];
			return new Promise((resolve) => {
				let cancel = () => {
					resolve(false);
					cancelBtn.removeEventListener("click", cancel);
					confirmBtn.removeEventListener("click", confirm);
					confirmDialog.classList.add("d-none");
				};
				let confirm = () => {
					resolve(true);
					cancelBtn.removeEventListener("click", cancel);
					confirmBtn.removeEventListener("click", confirm);
					confirmDialog.classList.add("d-none");
				};

				cancelBtn.addEventListener("click", cancel);
				confirmBtn.addEventListener("click", confirm);
				confirmDialog.classList.remove("d-none");
			});
		},

		showModal: async function (nextModal) {
			let promise = new Promise((resolve) => {
				$(nextModal).one("shown.bs.modal", () => {
					window.restdocCurrentModal = nextModal;
					resolve();
				});
			});

			if (window.restdocCurrentModal !== null) {
				$(window.restdocCurrentModal).one("hidden.bs.modal", () => {
					$(nextModal).modal("show");
				});
				$(window.restdocCurrentModal).modal("hide");
			} else {
				$(nextModal).modal("show");
			}

			return promise;
		},
		hideModal: async function () {
			return new Promise((resolve) => {
				if (window.restdocCurrentModal !== null) {
					$(window.restdocCurrentModal).one("hidden.bs.modal", () => {
						window.restdocCurrentModal = null;
						resolve();
					});
					$(window.restdocCurrentModal).modal("hide");
				} else {
					resolve();
				}
			});
		}
	},
	mounted: function () {
		this.descriptionEditorTrixEditor.addEventListener(
			"trix-selection-change",
			() => {
				//Highlight "Add table" button, if table selected

				let attachment = this.getAttachmentAtSelectedRange();
				if (
					attachment &&
					attachment instanceof Trix.Attachment &&
					attachment.getContentType() === "application/table-editor-table"
				) {
					this.descriptionEditorTableButton.classList.add("trix-active");
				} else {
					this.descriptionEditorTableButton.classList.remove("trix-active");
				}
			}
		);
		document.addEventListener("trix-action-invoke", async (event) => {
			if (event.actionName === "x-table") {
				//Custom "Add table" button
				await this.insertTable();
			}
		});
		for (let btn of document.getElementsByClassName("text-editor-cancel-btn")) {
			btn.addEventListener("click", async () => {
				await this.exitWithoutSaving();
			});
		}
		for (let btn of document.getElementsByClassName("text-editor-save-btn")) {
			btn.addEventListener("click", async (event) => {
				await this.saveDescription(event.currentTarget);
			});
		}
		//window.restdocApiDescriptionEditor = this;
		window.restdocApiEditDescription = this.editField.bind(this);
	}
};
</script>