
import Mark from "mark.js";
import { Vue } from "vue-class-component";
import { Prop, Inject } from "vue-property-decorator";

export default class GroupContentComponent extends Vue {
	@Prop({ default: null }) readonly model: any;
	public editorMode: boolean = false;
	@Inject({ default: false }) readonly canEdit!: boolean;
	private markInstance!: Mark;

	$refs!: {
		groupInfo: HTMLElement;
	};

	async created(): Promise<void> {
		this.$nextTick(() => {
			this.markInstance = new Mark(this.$refs.groupInfo);
			this.performMark();
		});
	}

	beforeUpdate(): void {
		this.clearMark();
	}

	updated(): void {
		this.$nextTick(() => {
			this.performMark();
		});
	}

	toggleEditorMode(state: any): void {
		if (state === "on") {
			this.editorMode = true;
		} else if (state === "off") {
			this.editorMode = false;
		} else {
			console.error(`toggleEditorMode: invalid state '${state}`);
		}
	}

	async editGroupDescription(): Promise<void> {
		const fieldOptions = {
			editorTitle: "Update group description",
			fieldType: "GroupDescription",
			shortDescr: false,
			groupId: this.model.groupId,
			fieldValue: this.model.groupDescription
		};
		try {
			const result = await (window as any).restdocApiEditDescription(
				fieldOptions
			);
			//eslint-disable-next-line vue/no-mutating-props
			this.model.groupDescription = result;
			console.log(`edited field value: ${result}`);
		} catch (e) {
			if (e instanceof Error) {
				//error happened
				throw e;
			} else {
				//promise rejected
				console.log(e);
			}
		}
	}

	async editGroupName(): Promise<void> {
		const fieldOptions = {
			editorTitle: "Update group name",
			fieldType: "GroupDescription",
			shortDescr: true,
			groupId: this.model.groupId,
			fieldValue: this.model.groupName
		};
		try {
			const result = await (window as any).restdocApiEditDescription(
				fieldOptions
			);
			// eslint-disable-next-line vue/no-mutating-props
			this.model.groupName = result;
			console.log(`edited field value: ${result}`);
		} catch (e) {
			if (e instanceof Error) {
				//error happened
				throw e;
			} else {
				//promise rejected
				console.log(e);
			}
		}
	}

	private clearMark(): void {
		if (this.markInstance) {
			this.markInstance.unmark();
		}
	}

	private performMark(): void {
		const keyword = this.$route.query["SearchPhrase"] as string;
		if (!keyword) {
			return;
		}
		// Remove previous marked elements and mark
		// the new keyword inside the context
		this.markInstance.unmark({
			done: () => {
				this.markInstance.mark(keyword);
			}
		});
	}
}
