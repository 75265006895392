<template>
	<table class="table table-striped table-bordered table-hover dde-table">
		<thead>
			<tr>
				<th>Parameter name (Regexp)</th>
				<th>Description</th>
			</tr>
		</thead>
		<tbody>
			<template v-if="paramsLoading">
				<tr>
					<td colspan="2">Loading...</td>
				</tr>
			</template>
			<tr
				v-for="[paramId, param] in Object.entries(params)"
				v-on:click="edit(paramId, param)"
				class="dde-editable"
				:key="paramId"
			>
				<td>{{ param.name }}</td>
				<td>{{ param.description }}</td>
			</tr>
		</tbody>
		<tfoot>
			<tr v-if="!paramsLoading" class="dde-create-new" v-on:click="create()">
				<td>...</td>
				<td>...</td>
			</tr>
		</tfoot>
	</table>
</template>

<script>
export default {
	props: { params: Object, paramsLoading: Boolean },
	methods: {
		edit: function (paramId, param) {
			this.$parent.showEditor(
				paramId,
				param,
				"param",
				"Update parameter default description"
			);
		},
		create: function () {
			this.$parent.showEditor(
				null,
				{},
				"param",
				"Create parameter default description"
			);
		}
	}
};
</script>