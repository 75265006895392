export const _restAuth = (function () {
	var _login = null;
	var _password = null;
	var _authorized = false;

	let stored = localStorage.getItem('restAuth');
	if (stored !== null) {
		let o = JSON.parse(stored);
		_login = o.login;
		_password = o.password;
		_authorized = true;
	}
	async function init() {
		if (_login !== null && _password !== null) {
			return;
		}
		let response = await fetch('/_/API/restAuth/UsingAppAuth', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		})
		if (!response.ok) {
			console.log(`Server responded with code ${response.status}`)
		} else {
			if (response.status === 200) {
				let credentials = await response.json();
				_login = credentials.login;
				_password = credentials.password;
				localStorage.setItem('restAuth', JSON.stringify({ login: _login, password: _password }))
				_authorized = true;
			}
		}
	}
	async function authorize(login, password) {
		if (login === _login && password === _password) {
			return true;
		}

		let response = await fetch('/_/API/restAuth', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				Login: login,
				Password: password
			})
		});
		if (!response.ok) {
			return false;
		} else {

			_login = login;
			_password = password;
			localStorage.setItem('restAuth', JSON.stringify({ login: _login, password: _password }))
			_authorized = true;
			return true;
		}
	}
	function unauthorize() {
		_login = null;
		_password = null;
		localStorage.removeItem('restAuth');
		_authorized = false;
	}
	function buildBasic() {
		if (!_authorized) {
			return null;
		}
		return 'Basic ' + btoa(_login + ':' + _password)
	}
	return {
		get login() {
			return _login;
		},
		get password() {
			return _password;
		},
		get authorized() {
			return _authorized;
		},
		authorize: authorize,
		unauthorize: unauthorize,
		buildBasic: buildBasic,
		init: init

	}
})();