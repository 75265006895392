<template>
	<div>
		<dde-method-table
			v-bind:methods="methods"
			v-bind:methods-loading="methodsLoading"
		></dde-method-table>
		<dde-param-table
			v-bind:params="params"
			v-bind:params-loading="paramsLoading"
		></dde-param-table>
		<dde-response-table
			v-bind:responses="responses"
			v-bind:responses-loading="responsesLoading"
		></dde-response-table>
		<div :id="'content-dde-modals'">
			<div
				class="modal fade"
				:id="'dde-modal'"
				data-keyboard="false"
				data-backdrop="false"
				tabindex="-1"
				role="dialog"
				aria-labelledby="editor-Title"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" :id="'exampleModalLongTitle'">
								{{ modal.title }}
							</h5>
						</div>
						<div class="modal-body">
							<component
								:is="modal.currentInputsComponent"
								v-bind:update-data="updateData"
							></component>
						</div>
						<div class="modal-footer">
							<button
								v-if="updateData.resourceId"
								type="button"
								class="btn btn-outline-danger dde-delete-btn mr-auto"
								v-bind:disabled="modal.saveInProgress"
								v-on:click="deleteResource()"
							>
								Delete
							</button>
							<button
								type="button"
								class="btn btn-secondary dde-cancel-btn"
								v-on:click="hideModal()"
								v-bind:disabled="modal.saveInProgress"
							>
								Close
							</button>
							<button
								v-if="updateData.resourceId"
								type="button"
								class="btn btn-primary dde-save-btn"
								data-save-in-process-text="Saving..."
								v-on:click="updateResource()"
								v-bind:disabled="modal.saveInProgress"
							>
								{{ modal.saveInProgress ? 'Saving...' : 'Save changes' }}
							</button>
							<button
								v-else
								type="button"
								class="btn btn-success dde-create-btn"
								data-save-in-process-text="Saving..."
								v-on:click="createResource()"
								v-bind:disabled="modal.saveInProgress"
							>
								{{ modal.saveInProgress ? 'Saving...' : 'Create' }}
							</button>
						</div>
						<div class="confirm-delete-dialog d-none">
							<div>
								<p>Delete description?</p>
							</div>
							<div>
								<button
									type="button"
									class="btn btn-secondary confirm-delete-dialog__cancel-btn"
								>
									Cancel
								</button>
								<button
									type="button"
									class="btn btn-danger confirm-delete-dialog__confirm-btn"
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ddeMethodTableComponent from "./DDEMethodTable.vue";
import ddeMethodModalInputsComponent from "./DDEMethodModalInputs.vue";
import ddeParamModalInputsComponent from "./DDEParamModalInputs.vue";
import ddeParamTableComponent from "./DDEParamTable.vue";
import ddeResponseModalInputsComponent from "./DDEResponseModalInputs.vue";
import ddeResponseTableComponent from "./DDEResponseTable.vue";

const $ = window.$;

export default {
	props: { model: Object },
	data: function () {
		return {
			methodsLoading: true,
			methods: {},
			paramsLoading: true,
			params: {},
			responsesLoading: true,
			responses: {},
			updateData: {},
			currentCollection: null,
			modal: {
				currentInputsComponent: "dde-method-modal-inputs",
				title: "",
				saveInProgress: false
			}
		};
	},
	components: {
		"dde-method-table": ddeMethodTableComponent,
		"dde-method-modal-inputs": ddeMethodModalInputsComponent,
		"dde-param-table": ddeParamTableComponent,
		"dde-param-modal-inputs": ddeParamModalInputsComponent,
		"dde-response-table": ddeResponseTableComponent,
		"dde-response-modal-inputs": ddeResponseModalInputsComponent
	},
	computed: {
		_lang: function () {
			return this.$route.params.lang;
		}
	},
	methods: {
		showEditor: async function showEditor(
			resourceId,
			resource,
			resourceName,
			modalTitle
		) {
			this.modal.title = modalTitle;
			this.updateData = { ...resource };
			this.updateData.lang = this._lang;
			this.updateData.resourceName = resourceName;
			this.updateData.resourceId = resourceId;
			if (this.updateData.resourceName === "method") {
				this.modal.currentInputsComponent = "dde-method-modal-inputs";
				this.currentCollection = this.methods;
			} else if (this.updateData.resourceName === "param") {
				this.modal.currentInputsComponent = "dde-param-modal-inputs";
				this.currentCollection = this.params;
			} else if (this.updateData.resourceName === "response") {
				this.modal.currentInputsComponent = "dde-response-modal-inputs";
				this.currentCollection = this.responses;
			} else {
				console.error(`unsupported resource ${this.updateData.resourceName}`);
				return;
			}
			await this.showModal();
		},

		showModal: async function showModal() {
			let modal = document.getElementById("dde-modal");
			return new Promise(function (resolve) {
				$(modal).one("shown.bs.modal", function () {
					resolve();
				});
				$(modal).modal("show");
			});
		},
		hideModal: async function hideModal() {
			let modal = document.getElementById("dde-modal");
			return new Promise(function (resolve) {
				$(modal).one("hidden.bs.modal", function () {
					resolve();
				});
				$(modal).modal("hide");
			});
		},

		createResource: async function createResource() {
			this.modal.saveInProgress = true;

			let response = await fetch(
				`/_/API/ApplicationApi/Default${this.updateData.resourceName}s`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify(this.updateData)
				}
			);
			if (!response.ok) {
				alert(`Server responded with code ${response.status}`);
			} else {
				let responseContent = await response.json();
				this.currentCollection[responseContent.id] = {
					...this.updateData
				};
				await this.hideModal();
			}
			this.modal.saveInProgress = false;
		},

		updateResource: async function updateResource() {
			this.modal.saveInProgress = true;

			let response = await fetch(
				`/_/API/ApplicationApi/Default${this.updateData.resourceName}s/${this.updateData.resourceId}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify(this.updateData)
				}
			);
			if (!response.ok) {
				alert(`Server responded with code ${response.status}`);
			} else {
				this.currentCollection[this.updateData.resourceId] = {
					...this.currentCollection[this.updateData.resourceId],
					...this.updateData
				};
				await this.hideModal();
			}
			this.modal.saveInProgress = false;
		},

		deleteResource: async function deleteResource() {
			this.modal.saveInProgress = true;

			if (await this.confirmDelete()) {
				let response = await fetch(
					`/_/API/ApplicationApi/Default${this.updateData.resourceName}s/${this.updateData.resourceId}`,
					{
						method: "DELETE"
					}
				);
				if (!response.ok) {
					alert(`Server responded with code ${response.status}`);
				} else {
					delete this.currentCollection[this.updateData.resourceId];
					await this.hideModal();
				}
			}

			this.modal.saveInProgress = false;
		},

		confirmDelete: async function confirmDelete() {
			let modal = document.getElementById("dde-modal");
			let confirmDialog = modal.getElementsByClassName(
				"confirm-delete-dialog"
			)[0];
			let cancelBtn = confirmDialog.getElementsByClassName(
				"confirm-delete-dialog__cancel-btn"
			)[0];
			let confirmBtn = confirmDialog.getElementsByClassName(
				"confirm-delete-dialog__confirm-btn"
			)[0];
			return new Promise(function (resolve) {
				var cancel = function () {
					resolve(false);
					cancelBtn.removeEventListener("click", cancel);
					confirmBtn.removeEventListener("click", confirm);
					confirmDialog.classList.add("d-none");
				};
				var confirm = function () {
					resolve(true);
					cancelBtn.removeEventListener("click", cancel);
					confirmBtn.removeEventListener("click", confirm);
					confirmDialog.classList.add("d-none");
				};

				cancelBtn.addEventListener("click", cancel);
				confirmBtn.addEventListener("click", confirm);
				confirmDialog.classList.remove("d-none");
			});
		}
	},
	created: async function () {
		fetch(`/_/${this._lang}/API/ApplicationApi/DefaultMethods`).then(
			async function (response) {
				if (response.ok) {
					let content = await response.text();
					this.methods = JSON.parse(content);
				} else {
					alert("HTTP-Error: " + response.status);
					this.contentComponent = "contentErrorComponent";
				}
				this.methodsLoading = false;
			}.bind(this)
		);

		fetch(`/_/${this._lang}/API/ApplicationApi/DefaultParams`).then(
			async function (response) {
				if (response.ok) {
					let content = await response.text();
					this.params = JSON.parse(content);
				} else {
					alert("HTTP-Error: " + response.status);
					this.contentComponent = "contentErrorComponent";
				}
				this.paramsLoading = false;
			}.bind(this)
		);

		fetch(`/_/${this._lang}/API/ApplicationApi/DefaultResponses`).then(
			async function (response) {
				if (response.ok) {
					let content = await response.text();
					this.responses = JSON.parse(content);
				} else {
					alert("HTTP-Error: " + response.status);
					this.contentComponent = "contentErrorComponent";
				}
				this.responsesLoading = false;
			}.bind(this)
		);
	}
};
</script>
