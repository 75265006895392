function getFileExtensionFormContentType(contentType) {
	if (contentType === 'text/plain') {
		return 'txt';
	} else {
		let ext = contentType.match(/\/([a-z]+)/)[1];
		return ext;
	}
}

export function downloadDataAsFile(name, blob) {
	let fileExtension = getFileExtensionFormContentType(blob.type);
	let filename = name + '.' + fileExtension;

	//blob = new Blob([data], { type: contentType });

	if (window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveBlob(blob, filename);
	}
	else {
		var elem = window.document.createElement('a');
		elem.href = window.URL.createObjectURL(blob);
		elem.download = filename;
		document.body.appendChild(elem);
		elem.click();
		document.body.removeChild(elem);
	}
}

export function copyTextToClipboard(text) {
	var textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		document.execCommand('copy');
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
	}

	document.body.removeChild(textArea);
}