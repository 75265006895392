<template>
	<div>
		<span class="dde__input-caption">Path (Regexp)</span>
		<input
			v-model="updateData.path"
			type="text"
			autocomplete="off"
			maxlength="200"
		/>

		<span class="dde__input-caption">Http method</span>
		<select v-model="updateData.httpMethod">
			<option
				v-for="value in ['GET', 'POST', 'DELETE', 'OPTIONS']"
				v-bind:value="value"
				:key="value"
			>
				{{ value }}
			</option>
		</select>

		<span class="dde__input-caption">Short description</span>
		<input
			v-model="updateData.description"
			type="text"
			autocomplete="off"
			maxlength="255"
		/>

		<span class="dde__input-caption">Long description</span>
		<div
			class="dde__long-descr-preview"
			v-html="updateData.longDescription"
			v-on:click="editLongDescription()"
		></div>
	</div>
</template>

<script>
export default {
	props: { updateData: Object },
	methods: {
		editLongDescription: async function () {
			let fieldOptions = {
				editorTitle: "Update method long description",
				shortDescr: false,
				fieldValue: this.updateData.longDescription ?? "",
				skipRemoteSave: true
			};
			await this.$parent.hideModal();
			try {
				let result = await window.restdocApiEditDescription(fieldOptions);
				this.updateData.longDescription = result;
			} catch (e) {
				if (e instanceof Error) {
					//error happened
					throw e;
				} else {
					//promise rejected
					console.log(e);
				}
			}
			await this.$parent.showModal();
		}
	}
};
</script>