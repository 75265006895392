
import Mark from "mark.js";
import { Options, Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";
import { _restAuth } from "@/modules/api-doc/services/rest-auth";
import OpenapiOperation from "./OpenApiOperation.vue";

@Options<OpenApiComponent>({
	components: {
		OpenapiOperation
	}
})
export default class OpenApiComponent extends Vue {
	@Inject({ default: true }) readonly canEdit!: boolean;
	private markInstance!: Mark;
	@Prop({ default: null }) readonly model: any;

	$refs!: {
		openApiInfo: HTMLElement;
		openApiUI: HTMLElement;
	};

	public editorMode = false;
	public restAuth = {
		inProgress: false,
		failed: false,
		authorized: false,
		restLoginInput: "",
		restPasswordInput: "",
		restLogin: ""
	};

	mounted(): void {
		const initialMethod = this.$route.query["Method"];
		if (initialMethod) {
			this.scrollToMethod(initialMethod as string);
		}
	}

	async authorize(): Promise<void> {
		this.restAuth.inProgress = true;
		const login = this.restAuth.restLoginInput;
		const psw = this.restAuth.restPasswordInput;
		const success = await _restAuth.authorize(login, psw);
		if (!success) {
			this.restAuth.failed = true;
		} else {
			this.restAuth.failed = false;
			this.restAuth.restLogin = login;
			this.restAuth.authorized = true;
		}
		this.restAuth.inProgress = false;
	}

	unauthorize(): void {
		this.restAuth.inProgress = true;
		_restAuth.unauthorize();
		this.restAuth.authorized = false;

		this.restAuth.inProgress = false;
	}

	toggleEditorMode(state: string): void {
		if (state === "on") {
			this.editorMode = true;
		} else if (state === "off") {
			this.editorMode = false;
		} else {
			console.error(`toggleEditorMode: invalid state '${state}`);
		}
	}

	async editServiceDescription(): Promise<void> {
		const fieldOptions = {
			editorTitle: "Update method long description",
			fieldType: "ServiceDescription",
			shortDescr: false,
			serviceId: this.model.serviceId,
			fieldValue: this.model.openApiDocument.info.description
		};
		try {
			const result = await (window as any).restdocApiEditDescription(
				fieldOptions
			);
			this.model.openApiDocument.info.description = result;
			console.log(`edited field value: ${result}`);
		} catch (e) {
			if (e instanceof Error) {
				//error happened
				throw e;
			} else {
				//promise rejected
				console.log(e);
			}
		}
	}

	async editServiceName(): Promise<void> {
		const fieldOptions = {
			editorTitle: "Update service name",
			fieldType: "ServiceDescription",
			shortDescr: true,
			serviceId: this.model.serviceId,
			fieldValue: this.model.openApiDocument.info.title
		};
		try {
			const result = await (window as any).restdocApiEditDescription(
				fieldOptions
			);
			// eslint-disable-next-line vue/no-mutating-props
			this.model.openApiDocument.info.title = result;
			console.log(`edited field value: ${result}`);
		} catch (e) {
			if (e instanceof Error) {
				//error happened
				throw e;
			} else {
				//promise rejected
				console.log(e);
			}
		}
	}

	scrollToMethod(methodSuffix: string): void {
		try {
			const methodElementId =
				"operations-default-" + methodSuffix.replace(".", "\\.");
			const element = document.querySelector("#" + methodElementId);
			const summary = document.querySelector(
				`#${methodElementId} > .opblock-summary`
			) as HTMLElement;
			summary?.click();
			setTimeout(
				() => element?.scrollIntoView({ behavior: "smooth", block: "start" }),
				100
			);
		} catch (e) {
			console.error(e);
		}
	}

	get operations(): any {
		var operations = [];
		for (const [pathKey, path] of Object.entries(
			this.model.openApiDocument.paths
		)) {
			for (const [operationKey, operation] of Object.entries(path as any)) {
				operations.push({
					pathKey: pathKey,
					path: path,
					operationKey: operationKey,
					operation: operation,
					editorMode: this.editorMode,
					showExecutorUI: this.showExecutorUI,
					components: this.model.openApiDocument.components
				});
			}
		}
		return operations;
	}

	get showExecutorUI(): boolean {
		if (this.editorMode) {
			return false;
		} else if (this.restAuth.authorized) {
			return true;
		} else {
			return false;
		}
	}

	async created(): Promise<void> {
		this.$nextTick(() => {
			this.markInstance = new Mark([
				this.$refs.openApiInfo,
				this.$refs.openApiUI
			]);
			this.performMark();
		});

		document.addEventListener(
			"scrollToMethod",
			(event: any) => {
				this.scrollToMethod(event.detail.method);
			}
		);
		await _restAuth.init();
		this.restAuth.restLogin = _restAuth.login;
		this.restAuth.authorized = _restAuth.authorized;
	}

	beforeUpdate(): void {
		this.clearMark();
	}

	updated(): void {
		this.$nextTick(() => {
			this.performMark();
		});
	}

	private clearMark(): void {
		if (this.markInstance) {
			this.markInstance.unmark();
		}
	}

	private performMark(): void {
		const keyword = this.$route.query["SearchPhrase"] as string;
		if (!keyword) {
			return;
		}
		// Remove previous marked elements and mark
		// the new keyword inside the context
		this.markInstance.unmark({
			done: () => {
				this.markInstance.mark(keyword);
			}
		});
	}
}
