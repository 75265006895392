
import Mark from "mark.js";
import { Vue } from "vue-class-component";
import { Prop, Inject } from "vue-property-decorator";
import { _restAuth } from "@/modules/api-doc/services/rest-auth";
import {
	downloadDataAsFile,
	copyTextToClipboard
} from "@/common/utils/utils.js";

export default class OpenApiOperation extends Vue {
	@Inject({ default: false }) readonly canEdit!: boolean;
	@Prop({ default: null }) readonly model: any;
	@Prop({ default: null }) pathKey!: string;
	@Prop({ default: null }) path!: any;
	@Prop({ default: null }) operationKey!: string;
	@Prop({ default: null }) operation!: any;
	@Prop({ default: false }) editorMode!: boolean;
	@Prop({ default: false }) showExecutorUI!: boolean;
	@Prop({ default: null }) components!: any;

	private markInstance!: Mark;
	public collapsed = true;
	public executeInProgress = false;
	public exampleToggled = true;
	public executeResult = {
		blob: "" as any,
		content: "",
		statusCode: "200"
	};

	$refs!: {
		root: HTMLElement;
	};

	public request = {
		body: "",
		contentType: this.operation.requestBody?.content
			? Object.keys(this.operation.requestBody.content)[0]
			: "",
		params: {} as any,
		accept: Object.keys(this.operation.responses[200].content)[0]
	};

	public toggleCollapse(): void {
		this.collapsed = !this.collapsed;
	}

	public formatXml(xml: any, tab: any): any {
		// tab = optional indent value, default is tab (\t)
		var formatted = "",
			indent = "";
		tab = tab || "\t";
		xml.split(/>\s*</).forEach(function (node: any) {
			if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
			formatted += indent + "<" + node + ">\r\n";
			if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab; // increase indent
		});
		return formatted.substring(1, formatted.length - 3);
	}

	initRequestBody(): void {
		//this.request.body = this.requestBodyTemplates[this.request.contentType]?.value || "";
		if (!this.operation.requestBody?.content) {
			this.request.body = "";
			return;
		}
		let content = this.operation.requestBody.content[this.request.contentType];
		if (!content?.example) {
			this.request.body = "";
		} else {
			this.request.body = content.example;
		}
	}

	contentTypeChanged(): void {
		this.initRequestBody();
	}

	getExample(exampleName: string): any {
		if (this.components?.examples === null || !exampleName) {
			return null;
		}
		return this.components.examples[exampleName];
	}

	getSchema(schemaName: string): any {
		if (this.components?.schemas === null || !schemaName) {
			return null;
		}
		return this.components.schemas[schemaName];
	}

	async execute(): Promise<void> {
		this.executeInProgress = true;

		let path = this.pathKey;
		let method = this.operationKey;
		let parameters = this.parameters;

		let requestUrl = new URL("/_/API/rest" + path, window.location.href);
		let options = {} as any;
		options.headers = {
			Pragma: "no-cache",
			"Cache-Control": "no-cache"
		};
		options.method = method;
		if (
			method.toLowerCase() !== "get" &&
			method.toLowerCase() !== "delete" &&
			method.toLowerCase() !== "options"
		) {
			options.body = this.request.body;
			options.headers["Content-Type"] = this.request.contentType;
		}
		if (parameters) {
			for (let param of parameters) {
				if (this.request.params[param.name]) {
					if (param.in === "path") {
						requestUrl.pathname = requestUrl.pathname.replace(
							`%7B${param.name}%7D`,
							this.request.params[param.name]
						);
					} else if (param.in === "header") {
						options.headers[param.name] = this.request.params[param.name];
					} else if (param.in === "query") {
						requestUrl.searchParams.append(
							param.name,
							this.request.params[param.name]
						);
					}
				}
			}
		}

		options.headers["Accept"] = this.request.accept;
		options.headers["Remove-WWW-Authenticate"] = "";
		let basicAuthStr = _restAuth.buildBasic();
		if (basicAuthStr !== null) {
			options.headers["Authorization"] = basicAuthStr;
		}

		let response = await fetch(requestUrl.toString(), options);

		let blob = await response.blob();

		if (typeof blob.text !== "function") {
			console.error("blob doesn't have function .text()");
		}
		let content = await blob.text();

		if (response.headers.get("Content-Type")?.includes("xml")) {
			this.executeResult.content = this.formatXml(content, "  ");
		} else {
			this.executeResult.content = content;
		}

		this.executeResult.blob = blob;
		this.executeResult.statusCode = response.status.toString();

		if (response.status === 401) {
			//if unauthorized
			alert("re-authentication required");
			(this.$parent as any).unauthorize();
		}

		this.executeInProgress = false;
	}

	downloadResponseContent(): void {
		downloadDataAsFile(this.operationSuffix, this.executeResult.blob);
	}

	copyResponseContent(): void {
		copyTextToClipboard(this.executeResult.content);
	}

	async editMethodShortDescr(): Promise<void> {
		let fieldOptions = {
			editorTitle: "Update method short description",
			fieldType: "OperationDescription",
			shortDescr: true,
			serviceId: (this.$parent as any).model.serviceId,
			methodId: this.operation.operationId,
			fieldValue: this.operation.summary
		};
		try {
			let result = await (window as any).restdocApiEditDescription(
				fieldOptions
			);
			this.operation.summary = result;
			console.log(`edited field value: ${result}`);
		} catch (e) {
			if (e instanceof Error) {
				//error happened
				throw e;
			} else {
				//promise rejected
				console.log(e);
			}
		}
	}

	async editMethodLongDescr(): Promise<void> {
		let fieldOptions = {
			editorTitle: "Update method long description",
			fieldType: "OperationDescription",
			shortDescr: false,
			serviceId: (this.$parent as any).model.serviceId,
			methodId: this.operation.operationId,
			fieldValue: this.operation.description
		};
		try {
			let result = await (window as any).restdocApiEditDescription(
				fieldOptions
			);
			this.operation.description = result;
			console.log(`edited field value: ${result}`);
		} catch (e) {
			if (e instanceof Error) {
				//error happened
				throw e;
			} else {
				//promise rejected
				console.log(e);
			}
		}
	}

	async editParamDescr(paramName: string): Promise<void> {
		let param = this.parameters.find((p: any) => p.name === paramName);
		if (!param) {
			console.error(`param '${paramName}' not found`);
			return;
		}
		let fieldOptions = {
			editorTitle: "Update parameter description",
			fieldType: "ParameterDescription",
			shortDescr: true,
			serviceId: (this.$parent as any).model.serviceId,
			methodId: this.operation.operationId,
			paramName: paramName,
			fieldValue: param.description
		};
		try {
			let result = await (window as any).restdocApiEditDescription(
				fieldOptions
			);
			param.description = result;
			console.log(`edited field value: ${result}`);
		} catch (e) {
			if (e instanceof Error) {
				//error happened
				throw e;
			} else {
				//promise rejected
				console.log(e);
			}
		}
	}

	async editResponseDescr(responseCode: string): Promise<void> {
		let response = this.operation.responses[responseCode];
		if (!response) {
			console.error(`response '${responseCode}' not found`);
			return;
		}
		let fieldOptions = {
			editorTitle: "Update response description",
			fieldType: "ResponseDescription",
			shortDescr: true,
			serviceId: (this.$parent as any).model.serviceId,
			methodId: this.operation.operationId,
			responseCode: responseCode,
			fieldValue: response.description
		};
		try {
			let result = await (window as any).restdocApiEditDescription(
				fieldOptions
			);
			response.description = result;
			console.log(`edited field value: ${result}`);
		} catch (e) {
			if (e instanceof Error) {
				//error happened
				throw e;
			} else {
				//promise rejected
				console.log(e);
			}
		}
	}

	async editRequestBodyTemplate(): Promise<void> {
		let currentXmlTemplate = "";
		for (let [contentType, content] of Object.entries<any>(
			this.operation.requestBody.content
		)) {
			if (contentType.toLowerCase().includes("xml")) {
				currentXmlTemplate = content.example || "";
				break;
			}
		}

		let fieldOptions = {
			editorTitle: "Update request body template",
			plainText: true,
			fieldValue: currentXmlTemplate,
			fetchUrl: "/_/API/ApplicationApi/MethodTemplates",
			valueName: "TemplateXml",
			updateData: {
				MethodId: this.operation.operationId
			}
		};
		try {
			let result = await (window as any).restdocApiEditDescription(
				fieldOptions
			);
			result = JSON.parse(result);

			for (let [contentType, content] of Object.entries<any>(
				this.operation.requestBody.content
			)) {
				if (contentType.toLowerCase().includes("xml")) {
					content.example = result?.templateXML;
				} else {
					content.example = result?.templateJSON;
				}
			}
			this.initRequestBody();
		} catch (e) {
			if (e instanceof Error) {
				//error happened
				throw e;
			} else {
				//promise rejected
				console.log(e);
			}
		}
	}

	toggleMethodExample(): void {
		this.exampleToggled = !this.exampleToggled;
	}

	async editMethodExample(): Promise<void> {
		let fieldOptions = {
			editorTitle: "Update method example",
			fieldValue: this.operation["x-restdoc-example"] ?? "",
			fetchUrl: "/_/API/ApplicationApi/MethodExamples",
			valueName: "Example",
			updateData: {
				MethodId: this.operation.operationId
			}
		};
		try {
			let result = await (window as any).restdocApiEditDescription(
				fieldOptions
			);
			result = JSON.parse(result);
			this.operation["x-restdoc-example"] = result?.example;
		} catch (e) {
			if (e instanceof Error) {
				//error happened
				throw e;
			} else {
				//promise rejected
				console.log(e);
			}
		}
	}

	validateXml(xmlString: string): boolean {
		var parser = new DOMParser();
		var parsererrorNS: any = parser
			.parseFromString("INVALID", "application/xml")
			.getElementsByTagName("parsererror")[0].namespaceURI;
		var dom = parser.parseFromString(xmlString, "application/xml");
		if (dom.getElementsByTagNameNS(parsererrorNS, "parsererror").length > 0) {
			return false;
		}
		return true;
	}

	copyUrlToClipboard(): void {
		let url = new URL(document.URL);
		url.searchParams.set("Method", this.operationSuffix);
		copyTextToClipboard(url.toString());
	}

	get operationSuffix(): string {
		// replace '/', '{' and '}' with '_'
		return (this.operationKey + this.pathKey)
			.replace(/\//g, "_")
			.replace(/{/g, "_")
			.replace(/}/g, "_");
	}

	get parameters(): any {
		return this.operation.parameters || [];
	}

	get responses(): any {
		return Object.entries(this.operation.responses).map(function (item: any) {
			return { code: item[0], ...item[1] };
		});
	}

	get requestBodyTemplate(): string {
		for (let [contentType, content] of Object.entries<any>(
			this.operation.requestBody.content
		)) {
			if (contentType.toLowerCase().includes("xml")) {
				return content.example || "";
			}
		}
		return "";
	}

	get methodExample(): string {
		return this.operation["x-restdoc-example"];
	}

	get isMethodExampleExpanded(): boolean {
		return this.exampleToggled || this.editorMode;
	}

	created(): void {
		this.$nextTick(() => {
			this.markInstance = new Mark(this.$refs.root);
			this.performMark();
		});
		this.initRequestBody();
	}

	beforeUpdate(): void {
		this.clearMark();
	}

	updated(): void {
		this.$nextTick(() => {
			this.performMark();
		});
	}

	private clearMark(): void {
		if (this.markInstance)	{
			this.markInstance.unmark();
		}
	}

	private performMark(): void {
		const keyword = this.$route.query["SearchPhrase"] as string;
		if (!keyword) {
			return;
		}
		// Remove previous marked elements and mark
		// the new keyword inside the context
		this.markInstance.unmark({
			done: () => {
				this.markInstance.mark(keyword);
			}
		});
	}
}
