<template>
	<div id="content-dynamic">
		<component :is="contentComponent" v-bind="props"></component>
	</div>
</template>

<script>
import LoadingCircle from '@/common/components/loading-circle/LoadingCircle.vue';
import GroupContentComponent from "./GroupContentComponent.vue";
import openapiContentComponent from "./OpenApiContent.vue";
import defaultDescriptionEditorContentComponent from "./DefaultDescriptionEditor/DefaultDescriptionEditor.vue";
import defaultContent from "./DefaultContent.vue";
export default {
	data: () => {
		return {
			contentComponent: "defaultContent",
			rawHtmlContent: "",
			model: {},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			switchContentHandler: () => {}
		};
	},
	components: {
		//urlContentComponent: urlContentComponent,
		openapiContentComponent: openapiContentComponent,
		groupContentComponent: GroupContentComponent,
		contentLoadingComponent: LoadingCircle,
		//contentErrorComponent: contentErrorComponent,
		defaultDescriptionEditorComponent: defaultDescriptionEditorContentComponent,
		defaultContent: defaultContent
	},
	methods: {
		switchContent: async function (
			contentDetails,
			initialServiceMethod = null
		) {
			this.contentComponent = "contentLoadingComponent";
			let content = "";
			let pageUrl = "";
			if (contentDetails.url) {
				let response = await fetch(contentDetails.url);
				if (!response.ok) {
					alert("HTTP-Error: " + response.status);
					this.contentComponent = "contentErrorComponent";
					return;
				} else {
					content = await response.text();
				}
			}
			if (contentDetails.contentType === "service") {
				this.model = JSON.parse(content);
				this.contentComponent = "openapiContentComponent";
				let serviceCode = contentDetails.key.replace("service", "");
				pageUrl = `${window.location.pathname}?ServiceCode=${serviceCode}`;
				if (initialServiceMethod) {
					pageUrl = `${pageUrl}&Method=${initialServiceMethod}`;
					setTimeout(() => {
						document.dispatchEvent(
							new CustomEvent("scrollToMethod", {
								detail: { method: initialServiceMethod }
							})
						);
					}, 200);
				}
			} else if (contentDetails.contentType === "group") {
				this.model = JSON.parse(content);
				this.contentComponent = "groupContentComponent";
				let groupId = contentDetails.key.replace("group", "");
				pageUrl = `${window.location.pathname}?GroupId=${groupId}`;
			} else if (contentDetails.contentType === "defaultDescriptionEditor") {
				this.model = {};
				this.contentComponent = "defaultDescriptionEditorComponent";
				let pagetreeKey = contentDetails.key;
				pageUrl = `${window.location.pathname}?PagetreeKey=${pagetreeKey}`;
			} else {
				console.error(`Unsupported content type ${contentDetails.contentType}`);
				this.contentComponent = "contentErrorComponent";
			}

			//history.pushState("", document.title, pageUrl);
		}
	},
	computed: {
		props: function () {
			if (this.contentComponent === "urlContentComponent") {
				return { htmlContent: this.rawHtmlContent };
			} else {
				return { model: this.model };
			}
		}
	},
	created() {
		this.switchContentHandler = function onSwitchContent(event) {
			this.switchContent(event.detail);
		}.bind(this);
		document.addEventListener("switchcontent", this.switchContentHandler, true);
		/*if (_initialPagetreeItem) {
			const urlParams = new URLSearchParams(window.location.search);
			const initialMethod = urlParams.get("Method");
			this.switchContent(_initialPagetreeItem, initialMethod);
		}*/
	},
	unmounted() {
		document.removeEventListener(
			"switchcontent",
			this.switchContentHandler,
			true
		);
	}
};
</script>
<style lang="sass" scoped>
</style>