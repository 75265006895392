import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-643920f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-slot" }
const _hoisted_2 = { class: "pagetree-header" }
const _hoisted_3 = { class: "pagetree-container" }
const _hoisted_4 = {
  class: "content-container",
  id: "content-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pagetree = _resolveComponent("pagetree")!
  const _component_loading_circle = _resolveComponent("loading-circle")!
  const _component_main_content = _resolveComponent("main-content")!
  const _component_description_editor = _resolveComponent("description-editor")!
  const _component_sidebar_wrapper = _resolveComponent("sidebar-wrapper")!

  return (_openBlock(), _createBlock(_component_sidebar_wrapper, null, {
    sidebar: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("apiDoc.pagetree.header")), 1),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.pagetree)
            ? (_openBlock(), _createBlock(_component_pagetree, {
                key: 0,
                selectedItem: _ctx.selectedItem,
                isLoading: _ctx.isPagetreeLoading,
                pagetree: _ctx.pagetree,
                "onUpdate:selectedItem": _ctx.onPagetreeItemSelected
              }, null, 8, ["selectedItem", "isLoading", "pagetree", "onUpdate:selectedItem"]))
            : (_openBlock(), _createBlock(_component_loading_circle, { key: 1 }))
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_main_content),
        _createVNode(_component_description_editor)
      ])
    ]),
    _: 1
  }))
}