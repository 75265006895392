import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: 'content-mode-control-btns'
}
const _hoisted_2 = {
  id: 'group-info',
  ref: "groupInfo"
}
const _hoisted_3 = {
  id: 'info-header',
  class: "info-header"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.canEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.editorMode)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn btn-outline-warning editor-mode-btn",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleEditorMode('off')))
              }))
            : _createCommentVNode("", true),
          (!_ctx.editorMode)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                type: "button",
                class: "btn btn-outline-success view-mode-btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleEditorMode('on')))
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, [
        (_ctx.editorMode)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass({
						empty: !_ctx.model.groupName,
						'editable-field': _ctx.model.allowEditGroupShortDescr
					}),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editGroupName()))
            }, _toDisplayString(_ctx.model.groupName ||
						(_ctx.model.allowEditGroupShortDescr ? "Add group name..." : "")), 3))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.model.groupName || ""), 1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.editorMode)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["editable-field", { empty: !_ctx.model.groupDescription }]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editGroupDescription())),
              innerHTML: _ctx.model.groupDescription || 'Add group description...'
            }, null, 10, _hoisted_6))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              innerHTML: _ctx.model.groupDescription || ''
            }, null, 8, _hoisted_7))
      ])
    ], 512)
  ]))
}