<template>
	<div>
		<span class="dde__input-caption">Parameter name (Regexp)</span>
		<input
			v-model="updateData.name"
			type="text"
			autocomplete="off"
			maxlength="200"
		/>

		<span class="dde__input-caption">Description</span>
		<input
			v-model="updateData.description"
			type="text"
			autocomplete="off"
			maxlength="255"
		/>
	</div>
</template>

<script>
export default {
	props: { updateData: Object }
};
</script>