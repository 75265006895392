
import { Vue, Options } from "vue-class-component";
import SidebarWrapper from "@/common/components/sidebar-wrapper/SidebarWrapper.vue";
import Pagetree from "@/common/components/pagetree/Pagetree.vue";
import { IServiceTreeItem, IServiceTreeModel } from "./models/service-tree";
import { apiDocService } from "./services/api-doc-service";
import MainContent from "./components/MainContent.vue";
import { InjectReactive, Watch } from "vue-property-decorator";
import { IAuthInfo } from "@/core/models/auth-info";
import { RouteLocation } from "vue-router";
import { getPagetreeItemByKey } from "@/common/models/pagetree";
import LoadingCircle from "@/common/components/loading-circle/LoadingCircle.vue";
import DescriptionEditor from "./components/DescriptionEditor.vue";

@Options<ApiDocView>({
	components: {
		SidebarWrapper,
		Pagetree,
		MainContent,
		LoadingCircle,
		DescriptionEditor
	},
	provide() {
		return {
			canEdit: (this.authInfo.roles?.indexOf("ApiEditor") ?? -1) >= 0
		};
	},
	beforeRouteUpdate(to, from, next): void {
		this.onRouteChange(to);
		next();
	}
})
export default class ApiDocView extends Vue {
	@InjectReactive() public authInfo!: IAuthInfo;

	public pagetree: IServiceTreeModel | null = null;
	public selectedItem: IServiceTreeItem | null = null;
	public isPagetreeLoading: boolean = false;

	async created(): Promise<void> {
		this.isPagetreeLoading = true;
		this.pagetree = await apiDocService.getServiceTree();
		this.onRouteChange(this.$route);
		this.isPagetreeLoading = false;
	}

	public onPagetreeItemSelected(item: IServiceTreeItem): void {
		if (item.contentType === "service") {
			this.$router.push({
				query: { ServiceCode: item.key.replace("service", "") }
			});
		} else if (item.contentType === "group") {
			this.$router.push({
				query: { GroupId: item.key.replace("group", "") }
			});
		} else {
			this.$router.push({
				query: { PagetreeKey: item.key }
			});
		}
	}

	public onRouteChange(to: RouteLocation): void {
		let selectedItemKey = "";
		if (to.query["ServiceCode"]) {
			selectedItemKey = "service" + to.query["ServiceCode"];
		} else if (to.query["GroupId"]) {
			selectedItemKey = "group" + to.query["GroupId"];
		} else if (to.query["PagetreeKey"]) {
			selectedItemKey = to.query["PagetreeKey"] as string;
		} else {
			this.selectedItem = null;
			return;
		}
		const selectedItem = getPagetreeItemByKey<IServiceTreeItem>(
			selectedItemKey,
			this.pagetree
		);
		if (selectedItem) {
			this.selectedItem = selectedItem;
		}
	}

	@Watch("selectedItem", {})
	public async onSelectedItemChange(item: IServiceTreeItem): Promise<void> {
		if (item)
			document.dispatchEvent(
				new CustomEvent("switchcontent", {
					detail: {
						url: item.url,
						contentType: item.contentType,
						key: item.key
					}
				})
			);
	}
}
