<template>
	<table class="table table-striped table-bordered table-hover dde-table">
		<thead>
			<tr>
				<th>Response code</th>
				<th>Description</th>
			</tr>
		</thead>
		<tbody>
			<template v-if="responsesLoading">
				<tr>
					<td colspan="2">Loading...</td>
				</tr>
			</template>
			<tr
				v-for="[responseId, response] in Object.entries(responses)"
				v-on:click="edit(responseId, response)"
				class="dde-editable"
				:key="responseId"
			>
				<td>{{ response.code }}</td>
				<td>{{ response.description }}</td>
			</tr>
		</tbody>
		<tfoot>
			<tr v-if="!responsesLoading" class="dde-create-new" v-on:click="create()">
				<td>...</td>
				<td>...</td>
			</tr>
		</tfoot>
	</table>
</template>

<script>
export default {
	props: { responses: Object, responsesLoading: Boolean },
	methods: {
		edit: function (responseId, response) {
			this.$parent.showEditor(
				responseId,
				response,
				"response",
				"Update response default description"
			);
		},
		create: function () {
			this.$parent.showEditor(
				null,
				{ code: 200 },
				"response",
				"Create response default description"
			);
		}
	}
};
</script>