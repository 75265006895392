<template>
	<table class="table table-striped table-bordered table-hover dde-table">
		<thead>
			<tr>
				<th>Path (Regexp)</th>
				<th style="width: 10%">Http method</th>
				<th>Short description</th>
			</tr>
		</thead>
		<tbody>
			<template v-if="methodsLoading">
				<tr>
					<td colspan="3">Loading...</td>
				</tr>
			</template>
			<tr
				v-for="[methodId, method] in Object.entries(methods)"
				v-on:click="edit(methodId, method)"
				class="dde-editable"
				data-resource-name="Method"
				:data-resource-id="methodId"
				data-editor-title="'Update method default description'"
				:key="methodId"
			>
				<td data-field-name="Path">{{ method.path }}</td>
				<td
					data-field-name="HttpMethod"
					data-field-options="GET;POST;DELETE;OPTIONS"
				>
					{{ method.httpMethod }}
				</td>
				<td data-field-name="Description">{{ method.description }}</td>
			</tr>
		</tbody>
		<tfoot>
			<tr
				v-if="!methodsLoading"
				class="dde-create-new"
				v-on:click="create()"
				data-resource-name="Method"
				data-editor-title="'Update method default description'"
			>
				<td data-field-name="Path">...</td>
				<td
					data-field-name="HttpMethod"
					data-field-options="GET;POST;DELETE;OPTIONS"
				>
					...
				</td>
				<td data-field-name="Description">...</td>
			</tr>
		</tfoot>
	</table>
</template>

<script>
export default {
	props: { methods: Object, methodsLoading: Boolean },
	methods: {
		edit: function (methodId, method) {
			this.$parent.showEditor(
				methodId,
				method,
				"method",
				"Update method default description"
			);
		},
		create: function () {
			this.$parent.showEditor(
				null,
				{ httpMethod: "GET" },
				"method",
				"Create method default description"
			);
		}
	}
};
</script>