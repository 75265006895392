<template >
	<div ref="root" id="table-editor-element">
		<div class="modal-header">
			<h5 class="modal-title d-none" id="table-editor-modal-title">
				Modal title
			</h5>
		</div>
		<div class="modal-body">
			<trix-toolbar id="table-trix-toolbar">
				<div class="trix-button-row">
					<span
						class="trix-button-group trix-button-group--text-tools"
						data-trix-button-group="text-tools"
					>
						<button
							type="button"
							class="trix-button trix-button--icon trix-button--icon-bold"
							data-trix-attribute="bold"
							data-trix-key="b"
							title="Bold"
							tabindex="-1"
						>
							Bold
						</button>
						<button
							type="button"
							class="trix-button trix-button--icon trix-button--icon-italic"
							data-trix-attribute="italic"
							data-trix-key="i"
							title="Italic"
							tabindex="-1"
						>
							Italic
						</button>
						<button
							type="button"
							class="trix-button trix-button--icon trix-button--icon-strike"
							data-trix-attribute="strike"
							title="Strikethrough"
							tabindex="-1"
						>
							Strikethrough
						</button>
						<button
							type="button"
							class="trix-button trix-button--icon trix-button--icon-link"
							data-trix-attribute="href"
							data-trix-action="link"
							data-trix-key="k"
							title="Link"
							tabindex="-1"
						>
							Link
						</button>
					</span>
					<span
						class="trix-button-group trix-button-group--table-tools"
						data-trix-button-group="table-tools"
					>
						<button
							type="button"
							class="
								trix-button
								trix-button--icon
								trix-button--icon-table-horizontal-heading
							"
							v-bind:class="{ 'trix-active': horizontalHeadingEnabled }"
							v-on:click="toggleHorizontalHeading()"
							title="Toggle Horizontal Heading"
							tabindex="-1"
						>
							Toggle Horizontal Heading
						</button>
						<button
							type="button"
							class="
								trix-button
								trix-button--icon
								trix-button--icon-table-vertical-heading
							"
							v-bind:class="{ 'trix-active': verticalHeadingEnabled }"
							v-on:click="toggleVerticalHeading()"
							title="Toggle Vertical Heading"
							tabindex="-1"
						>
							Toggle Vertical Heading
						</button>
						<button
							type="button"
							class="
								trix-button trix-button--icon trix-button--icon-table-row-add
							"
							v-on:click="addRow()"
							v-on:mouseover="visualizeRowAdd(true)"
							v-on:mouseleave="visualizeRowAdd(false)"
							title="Add Row"
							tabindex="-1"
						>
							Add Row
						</button>
						<button
							type="button"
							class="
								trix-button trix-button--icon trix-button--icon-table-column-add
							"
							v-on:click="addColumn()"
							v-on:mouseover="visualizeColumnAdd(true)"
							v-on:mouseleave="visualizeColumnAdd(false)"
							title="Add Column"
							tabindex="-1"
						>
							Add Column
						</button>
						<button
							type="button"
							class="
								trix-button trix-button--icon trix-button--icon-table-row-remove
							"
							v-on:click="removeRow()"
							v-on:mouseover="visualizeRowDelete(true)"
							v-on:mouseleave="visualizeRowDelete(false)"
							title="Remove Row"
							tabindex="-1"
						>
							Remove Row
						</button>
						<button
							type="button"
							class="
								trix-button
								trix-button--icon
								trix-button--icon-table-column-remove
							"
							v-on:click="removeColumn()"
							v-on:mouseover="visualizeColumnDelete(true)"
							v-on:mouseleave="visualizeColumnDelete(false)"
							title="Remove Column"
							tabindex="-1"
						>
							Remove Column
						</button>
					</span>
					<span
						class="trix-button-group trix-button-group--history-tools"
						data-trix-button-group="history-tools"
					>
						<button
							type="button"
							class="trix-button trix-button--icon trix-button--icon-undo"
							data-trix-action="undo"
							data-trix-key="z"
							title="Undo"
							tabindex="-1"
							disabled=""
						>
							Undo
						</button>
						<button
							type="button"
							class="trix-button trix-button--icon trix-button--icon-redo"
							data-trix-action="redo"
							data-trix-key="shift+z"
							title="Redo"
							tabindex="-1"
							disabled=""
						>
							Redo
						</button>
					</span>
				</div>

				<div class="trix-dialogs" data-trix-dialogs="">
					<div
						class="trix-dialog trix-dialog--link"
						data-trix-dialog="href"
						data-trix-dialog-attribute="href"
					>
						<div class="trix-dialog__link-fields">
							<input
								type="url"
								name="href"
								class="trix-input trix-input--dialog"
								placeholder="Enter a URL…"
								aria-label="URL"
								required=""
								data-trix-input=""
								disabled="disabled"
							/>
							<div class="trix-button-group">
								<input
									type="button"
									class="trix-button trix-button--dialog"
									value="Link"
									data-trix-method="setAttribute"
								/>
								<input
									type="button"
									class="trix-button trix-button--dialog"
									value="Unlink"
									data-trix-method="removeAttribute"
								/>
							</div>
						</div>
					</div>
				</div>
			</trix-toolbar>
			<input v-model="inputValue" id="trix-table-input" type="hidden" />
			<div id="trix-table-editor-hidden-container" class="d-none">
				<trix-editor
					v-if="showTrixEditor"
					id="table-trix-editor"
					toolbar="table-trix-toolbar"
					input="trix-table-input"
				></trix-editor>
			</div>
			<div id="table-editor-table-container">
				<table
					class="table-attachment"
					v-bind:class="{
						'horizontal-heading': horizontalHeadingEnabled,
						'vertical-heading': verticalHeadingEnabled
					}"
				>
					<tbody>
						<tr v-for="y in table.keys()">
							<td
								v-for="x in table[y].keys()"
								v-on:click="switchField(x, y, $event.currentTarget)"
								v-bind:class="{
									'table-editor-td--red':
										y === rowToDelete || x === columnToDelete,
									'table-editor-td--border-right-green': x === columnToAdd,
									'table-editor-td--border-bottom-green': y === rowToAdd
								}"
							>
								<template v-if="currentX === x && currentY === y"> </template>
								<div v-else v-html="table[y][x]"></div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="modal-footer">
			<button
				type="button"
				class="btn btn-secondary table-editor-cancel-btn"
				v-on:click="closeTableEditor(false)"
			>
				Cancel
			</button>
			<button
				type="button"
				class="btn btn-primary table-editor-save-btn"
				v-on:click="closeTableEditor(true)"
			>
				Save table
			</button>
		</div>
	</div>
</template>

<script>
import { showModal } from "./DescriptionEditor.vue";
const $ = window.$;

export default {
	data: () => {
		return {
			table: [],
			showTrixEditor: false,
			currentX: -1,
			currentY: -1,
			inputValue: "",
			horizontalHeadingEnabled: false,
			verticalHeadingEnabled: false,
			resolver: null,
			rejecter: null,
			rowToAdd: -1,
			columnToAdd: -1,
			rowToDelete: -1,
			columnToDelete: -1
		};
	},
	computed: {
		//dimensionX: function () {
		//    return this.table[0].length;
		//},
		//dimensionY: function () {
		//    return this.table.length;
		//}
	},
	methods: {
		showTableEditor: async function (srcTableHtml) {
			if (srcTableHtml) {
				//from existing table html
				var div = document.createElement("div");
				div.innerHTML = srcTableHtml;
				let selectedTableElement = div.childNodes[0];

				this.table = [];
				for (var i = 0; selectedTableElement.rows[i] !== undefined; i++) {
					let row = selectedTableElement.rows[i];
					this.table[i] = [];
					//iterate through rows
					//rows would be accessed using the "row" variable assigned in the for loop
					for (var j = 0; row.cells[j] !== undefined; j++) {
						let cell = row.cells[j];
						this.table[i][j] = cell.innerHTML;
					}
				}

				this.horizontalHeadingEnabled =
					selectedTableElement.classList.contains("horizontal-heading");
				this.verticalHeadingEnabled =
					selectedTableElement.classList.contains("vertical-heading");
			} else {
				//new table
				this.table = [
					["", "", ""],
					["", "", ""],
					["", "", ""]
				];

				this.horizontalHeadingEnabled = true;
				this.verticalHeadingEnabled = false;
			}
			await showModal(document.getElementById("table-editor-modal"));

			let promise = new Promise(
				(resolve, reject) => {
					this.resolver = resolve;
					this.rejecter = reject;
				}
			);

			return promise;
		},
		closeTableEditor: function (saveChanges) {
			this.hideEditor();
			if (!saveChanges) {
				this.rejecter("table discarded");
			} else {
				let tableElement = document.createElement("table");
				let tableBodyElement = document.createElement("tbody");
				for (let row of this.table) {
					let rowElement = document.createElement("tr");
					for (let cell of row) {
						let cellElement = document.createElement("td");
						cellElement.innerHTML = cell;
						rowElement.appendChild(cellElement);
					}
					tableBodyElement.appendChild(rowElement);
				}
				tableElement.appendChild(tableBodyElement);
				tableElement.classList.add("table-attachment");
				if (this.horizontalHeadingEnabled) {
					tableElement.classList.add("horizontal-heading");
				}
				if (this.verticalHeadingEnabled) {
					tableElement.classList.add("vertical-heading");
				}
				this.resolver(tableElement.outerHTML);
			}
		},
		switchField: function (newX, newY) {
			let previosX = this.currentX;
			let previosY = this.currentY;

			if (newX === previosX && newY === previosY) {
				return;
			}
			let tableTrixEditor = document.getElementById("table-trix-editor");
			let cell =
				this.$refs["root"].getElementsByClassName("table-attachment")[0].rows[
					newY
				].cells[newX];
			if (previosX >= 0 && previosY >= 0) {
				this.table[previosY][previosX] = tableTrixEditor.innerHTML;
			}
			tableTrixEditor.editor.loadHTML(this.table[newY][newX]);
			this.currentX = newX;
			this.currentY = newY;
			cell.appendChild(tableTrixEditor);
			tableTrixEditor.focus();
			tableTrixEditor.editor.setSelectedRange(
				tableTrixEditor.editor.getDocument().toString().length - 1
			);
		},
		hideEditor: function () {
			let previosX = this.currentX;
			let previosY = this.currentY;
			let newX = -1;
			let newY = -1;
			let tableTrixEditor = document.getElementById("table-trix-editor");
			if (newX === previosX && newY === previosY) {
				return;
			}
			if (previosX >= 0 && previosY >= 0) {
				this.table[previosY][previosX] = tableTrixEditor.innerHTML;
			}
			tableTrixEditor.editor.loadHTML("");
			this.currentX = newX;
			this.currentY = newY;
			document
				.getElementById("trix-table-editor-hidden-container")
				.appendChild(tableTrixEditor);
		},
		toggleHorizontalHeading: function () {
			this.horizontalHeadingEnabled = !this.horizontalHeadingEnabled;
		},
		toggleVerticalHeading: function () {
			this.verticalHeadingEnabled = !this.verticalHeadingEnabled;
		},
		addRow: function () {
			if (this.currentX < 0 || this.currentY < 0) {
				return;
			}
			let tmpY = this.currentY;
			let tmpX = this.currentX;
			this.hideEditor();
			this.table.splice(tmpY + 1, 0, Array(this.table[0].length).fill(""));
			this.switchField(tmpX, tmpY);
		},
		addColumn: function () {
			if (this.currentX < 0 || this.currentY < 0) {
				return;
			}
			let tmpY = this.currentY;
			let tmpX = this.currentX;
			this.hideEditor();
			for (let row of this.table) {
				row.splice(tmpX + 1, 0, "");
			}
			this.switchField(tmpX, tmpY);
		},
		removeRow: function () {
			if (this.currentX < 0 || this.currentY < 0 || this.table.length < 2) {
				return;
			}
			let tmpY = this.currentY;
			let tmpX = this.currentX;
			this.hideEditor();
			this.table.splice(tmpY, 1);

			this.switchField(tmpX, tmpY === 0 ? 0 : tmpY - 1);
		},
		removeColumn: function () {
			if (this.currentX < 0 || this.currentY < 0 || this.table[0].length < 2) {
				return;
			}

			let tmpY = this.currentY;
			let tmpX = this.currentX;
			this.hideEditor();
			for (let row of this.table) {
				row.splice(tmpX, 1);
			}
			this.switchField(tmpX === 0 ? 0 : tmpX - 1, tmpY);
		},
		visualizeRowAdd: function (turnOn) {
			if (turnOn) {
				this.rowToAdd = this.currentY;
			} else {
				this.rowToAdd = -1;
			}
		},
		visualizeColumnAdd: function (turnOn) {
			if (turnOn) {
				this.columnToAdd = this.currentX;
			} else {
				this.columnToAdd = -1;
			}
		},
		visualizeRowDelete: function (turnOn) {
			if (turnOn) {
				this.rowToDelete = this.currentY;
			} else {
				this.rowToDelete = -1;
			}
		},
		visualizeColumnDelete: function (turnOn) {
			if (turnOn) {
				this.columnToDelete = this.currentX;
			} else {
				this.columnToDelete = -1;
			}
		}
	},
	//created: async function () {},
	mounted: function () {
		this.showTrixEditor = true;
		window.restdocApiEditTable = this.showTableEditor.bind(this);
	}
};
</script>